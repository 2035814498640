@import "../../Style/mixin.scss";

.our-science-covers {
    @include bgSetup($bg-image: url('../assets/images/pattern/pattern-3.png'), $bg-position: bottom right, $bg-size: cover);

    @media(max-width:600px) {
        background-size: contain;
    }

    .card {
        @include card;
        min-height: 470px;

        @media (max-width: 900px) {
            min-height: auto;
        }

        p {
            font-size: 19px;
            text-transform: capitalize;
        }

        img {
            width: 80%;
            margin: 0 auto;

            @media(max-width: 500px) {
                width: 100%;
            }
        }

        &.color-2 {
            @include card($bg-color: #F2DBDB)
        }
    }

    .shape-2 {
        &::before {

            @include beforeContent ($height: 50px, $top: -41px, $left: unset, $right: -151px, $width: 72%, $opacity: 1);
            @include bgSetup ($bg-image: url('../assets/images/pattern/shape-4.svg'), $bg-size: contain);

            @media(max-width: 600px) {
                display: none;
            }
        }
    }

    .shape-3 {
        &::after {

            @include beforeContent ($height: 50px, $top: unset, $bottom: - 41px, $left: unset, $right: -151px, $width: 72%, $opacity: 1);
            @include bgSetup ($bg-image: url('../assets/images/pattern/shape-5.svg'), $bg-size: contain);

            @media(max-width: 767px) {
                display: none;
            }
        }
    }
}