@import "../../Style/mixin.scss";

.top-banner {
    position: relative;
    overflow: hidden;
    // &::before {
    //     @include beforeContent($opacity: 1);
    //     @include bgSetup($bg-image: url('../assets/images/pattern/shape-2.svg'), $bg-position: top right, $bg-size: contain);
    //     z-index: 1;
    // }

    &::after {
        @include beforeContentLeft($background: linear-gradient(45deg, var(--dark-blue), transparent), $opacity: 1);
    }

    .slick-slider {
        max-height: 460px;

        @media (max-width: 600px) {
            max-height: 400px;
        }

        .slick-list {

            .list {
                width: 100%;
                display: flex;
                justify-content: center;
                text-align: center;

                img {
                    width: 100%;
                    margin: 0 auto;
                    display: flex;

                }

                @media (min-aspect-ratio: 16/9) {
                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                @media (max-aspect-ratio: 16/9) {
                    img {
                        width: 170%;
                        height: 100%;

                    }
                }

            }
        }
    }



    .caption {
        width: 100%;
        position: relative;
        z-index: 1;

        h1 {
            font-size: 71px;
            margin-bottom: 0;

            @media(max-width: 600px) {
                font-size: 45px;
            }

            @media(max-width: 500px) {
                font-size: 30px;
            }
        }

        p {
            font-size: 30px;
        }
    }

    .center-contents {
        bottom: 0;
        max-height: 460px;
        min-height: 460px;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 1;

        @media (max-width: 600px) {
            min-height: 400px;
            max-height: 400px;
        }
    }

}