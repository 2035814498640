@import "../../Style/mixin.scss";

.news {
    display: block;
    border: 1px solid #dadce0;
    border-radius: 8px;
    box-shadow: none;
    margin-bottom: 16px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background: var(--white);

    span {
        @include BebasNeueBold;
        font-size: 22px;
        letter-spacing: 2px;
        margin-bottom: 10px;

        &.d-block {
            font-size: 14px;
            color: #838383;
            @include FlamaRegular;
            letter-spacing: 0;
        }
    }

    .ant-image {
        img {
            width: 100%;
        }
    }

    p {
        margin-bottom: 0;
    }

    a {
        font-size: 15px;
    }

    img {
        width: 80%;
    }

    &:hover {
        -webkit-box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.12);
    }
}