// Flama fonts
@font-face {
    font-family: "Flama";
    src: url("../assets/font/flama/Flama-Light-Regular.otf") format("opentype");
    font-weight: 300;
}

@font-face {
    font-family: "Flama";
    src: url("../assets/font/flama/Flama-Light-Italic.otf") format("opentype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Flama";
    src: url("../assets/font/flama/Flama-Regular.otf") format("opentype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Flama";
    src: url("../assets/font/flama/Flama-Italic.otf") format("opentype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Flama";
    src: url("../assets/font/flama/Flama-Bold.otf") format("opentype");
    font-weight: 600;
}

@font-face {
    font-family: "Flama";
    src: url("../assets/font/flama/Flama-Bold-Italic.otf") format("opentype");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Flama";
    src: url("../assets/font/flama/Flama-Black-Regular.otf") format("opentype");
    font-weight: 900;
}

@font-face {
    font-family: "Flama";
    src: url("../assets/font/flama/Flama-Black-Italic.otf") format("opentype");
    font-weight: 900;
    font-style: italic;
}

// Bebas Neue
@font-face {
    font-family: "BebasNeue";
    src: url("../assets/font/bebas-neue/Bebas-Neue-Thin.otf") format("opentype");
    font-weight: 100;
}

@font-face {
    font-family: "BebasNeue";
    src: url("../assets/font/bebas-neue/Bebas-Neue-Light.otf") format("opentype");
    font-weight: 300;
}

@font-face {
    font-family: "BebasNeue";
    src: url("../assets/font/bebas-neue/Bebas-Neue-Regular.otf") format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: "BebasNeue";
    src: url("../assets/font/bebas-neue/Bebas-Neue-Book.otf") format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: "BebasNeue";
    src: url("../assets/font/bebas-neue/Bebas-Neue-Bold.otf") format("opentype");
    font-weight: 600;
}