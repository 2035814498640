.team-details {
    h3 {
        font-size: 20px;
        margin-bottom: 5px;
        margin-top: 0;
    }

    .position {
        font-size: 18px;
        margin-bottom: 10px;
        color: var(--dark-blue);
        font-weight: bold;
    }

    div[data-index="2"] {
        .list.leadership {
            .card {
                min-height: auto;
            }
        }
    }

    .list.leadership {
        .card {
            min-height: 295px;
        }
    }

    .list.board {
        .card {
            min-height: 200px;
        }
    }

    .list {

        .card {
            display: inline-block;

            .image {
                width: 30%;

                img {
                    width: 100%;
                    border-top-left-radius: 5px;
                    border-bottom-right-radius: 30px;
                }
            }

            .details {
                width: 70%;
                padding: 18px;
            }

            .image,
            .details {
                float: left;
            }
        }
    }


    .slick-dots {
        bottom: -44px;
    }

    .slick-dots li button:before {
        color: var(--dark-blue);
    }

    .slick-dots li.slick-active button:before {
        color: var(--blue);
    }
}