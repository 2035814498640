@import "./mixin.scss";
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  --blue: #204ECF;
  --dark-blue: #0F256E;
  --eigengrau: #1F2027;
  --white: #fff;
  --white-smoke: #f5f5f5;
  --gray: #e2e2e1;
  --gray-dark: #343a40;
  --success: #28a745;
  --green: #0bb479;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light-gray: #ececec;
  --black: #000;
  --light-blue: #E1ECFF;
  --facebook-blue: #3869BD;
  --gray-color: #CCCCCC;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

// select text color starts
::-moz-selection {
  color: var(--white);
  background: var(--blue);
}

::selection {
  color: var(--white);
  background: var(--blue);
}

// select text color ends

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body,
p,
ol,
ul,
a {
  font-family: 'Roboto', sans-serif;
  line-height: 165%;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-size: 16px;
  line-height: 165%;
  color: #22262a;
  background-color: var(--white);
}

p,
ol,
ul {
  color: #626262;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1 {
  font-size: 35px;
  font-weight: 700;

  @media(max-width: 600px) {
    font-size: 30px;
  }
}

h2 {
  font-size: 37px;

  @media(max-width: 600px) {
    font-size: 30px;
  }

  @media(max-width: 500px) {
    font-size: 25px;
  }
}

h3 {
  font-size: 30px;

  @media(max-width: 600px) {
    font-size: 25px;
  }

  @media(max-width: 500px) {
    font-size: 20px;
  }
}

h4,
h5 {
  font-size: 25px;

  @media(max-width: 600px) {
    font-size: 20px;
  }

  @media(max-width: 500px) {
    font-size: 18px;
  }
}

h6 {
  font-size: 20px;

  @media(max-width: 600px) {
    font-size: 17px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'IBM Plex Serif', serif;
  // @include BebasNeueBold;
  // font-family: 'Libre Baskerville', serif;
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 165%;
}

p,
ol,
ul {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 1rem;
}

p:last-child {
  margin-bottom: 0;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  text-decoration: none;
  background-color: transparent;
  color: var(--primary);
}

a:hover {
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.container {
  -webkit-flex: 0 0 1190px;
  -moz-box-flex: 0;
  -ms-flex: 0 0 1190px;
  flex: 0 0 1190px;
  max-width: 1190px;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

a:hover {
  text-decoration: none;
}

.ant-layout {
  // background: url('../assets/images/patterns/pattern-1.png');
  // background-size: cover;
  background: var(--white);
}

.anticon {
  vertical-align: 0 !important;
}

button {
  border-radius: 5px;
}


.btn,
.ant-btn {
  display: inline-block;
  font-weight: 600;
  color: #6F7580;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  height: auto;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 5px 15px;
  letter-spacing: 0.1rem;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

a.standard,
button,
button.primary,
.ant-btn-primary,
.ant-btn-secondary,
.ant-btn-standard,
.ant-btn-button.secondary,
.btn.btn-primary {
  outline: none;
  width: auto;
  font-size: 15px;
  border-radius: 0;
  border-radius: 3px;
  box-shadow: none;
  text-shadow: none;
  padding: 10px 18px;
}

a.standard,
.ant-btn-primary,
.btn.btn-primary {
  color: var(--white);
  background: var(--blue);
  border-color: var(--blue);
  transition: all 400ms ease;
  // box-shadow: 0 20px 50px #2dccc240;
}

.ant-btn-primary:active,
a.standard:hover,
.ant-btn-primary:hover,
.ant-btn-primary:focus,
.btn.btn-primary:hover {
  background: var(--dark-blue) !important;
  border-color: var(--dark-blue) !important;
}

.ant-btn-button.secondary,
.btn.btn-secondary,
.ant-btn-secondary,
.ant-btn-button.secondary:hover,
.btn.btn-secondary:hover,
.ant-btn-secondary:hover {
  background-color: transparent;
  border: 2px solid var(--dark-blue);
  color: var(--dark-blue);
}

.ant-btn-link {
  color: var(--primary);
}

button i {
  margin-right: 8px;
}

button i.no-margin {
  margin-right: 0;
}

.border-left-red {
  border-left: 4px solid var(--blue);
}

// input fields starts

input[type="text"],
input[type="password"],
input[type="number"],
input[type="date"],
.ant-picker,
select,
.ant-input {
  width: 100%;
  padding: 7px 15px;
  outline: none;
  font-size: 15px;
  position: relative;

}

.ant-input {
  border-radius: 4px;
}

.ant-input:hover,
.ant-input:focus,
.ant-input-focused {
  border-color: #d9d9d9;
  box-shadow: none;
}

input[type="text"],
input[type="text"]:hover,
input[type="text"]:focus {
  outline: none;
}

.ant-input-affix-wrapper input[type="text"],
.ant-input-affix-wrapper input[type="password"] {
  height: auto;
  padding: 6px 10px;
}

.ant-checkbox-wrapper {
  font-size: 14px;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: none;
  outline: none;
  box-shadow: none;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: var(--primary);
  border-color: var(--primary);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: var(--primary);
  outline: none;
}

.ant-checkbox-checked::after {
  border: 0;
}

.ant-slider-track,
.ant-slider:hover .ant-slider-track {
  background: var(--primary);
}

.ant-slider-handle,
.ant-slider-handle:focus,
.ant-slider-dot-active,
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: var(--primary);
}

.ant-slider-mark-text {
  font-size: 12px;
  width: 34px;
}

// input fields ends here

// header changes
.ant-layout-header {
  height: 80px;
  padding: 0 40px !important;
  color: #333;
  line-height: 64px;
  background: #fff !important;
  background: #fff;
  box-shadow: 0 2px 8px #f0f1f2;
}

// headder changes ends


// .ant-modal-header
.ant-modal-header {
  border-bottom: 0;
  padding: 30px 30px 0 30px;
}

.ant-modal-body {
  padding: 30px;
}

.ant-modal-title {
  font-size: 24px;
  font-family: var(--Helvetica-Bold);
}


// center content
.center-contents {
  display: table;

  .middle-content {
    display: table-cell;
    vertical-align: middle;
    position: relative;
  }
}


// custom section titles
.section-title {
  font-size: 35px;

  @media(max-width: 900px) {
    font-size: 40px;
  }

  @media(max-width: 600px) {
    font-size: 35px;
  }

  @media(max-width: 500px) {
    font-size: 30px;
  }
}

.section-subtitle {
  font-size: 31px;

  @media(max-width: 900px) {
    font-size: 22px;
  }

  @media(max-width: 600px) {
    font-size: 20px;
  }

  @media(max-width: 500px) {
    font-size: 22px;
  }
}


// Typography

.border-radius {
  border-radius: 10px;
}

.font-weight-bold {
  font-weight: 700;
}

.text-blue {
  color: var(--blue);
}

.text-darkblue {
  color: var(--dark-blue);
}

// background colors

.bg-light-blue {
  background: var(--light-blue);
}

.bg-gray {
  background-color: #f7f7f7;
}

.section-spacing {
  padding: 80px 0;
}

//shapes
.shape-1 {
  background: url('../assets/images/pattern/shape-1.svg');
  @include bgRepeatPosition($position: top right);
}

.read-more {
  font-size: 17px;

  i {
    margin-left: 6px;
  }
}

// move down
.move-down {
  position: absolute;
  bottom: 40px;
  right: 0;
  left: 0;
  z-index: 2;
  animation: 1s linear 4s infinite alternate yoyo;

  img {
    height: 40px;
    cursor: pointer;
  }
}

@keyframes yoyo {
  from {
    transform: translateY(-20px);
  }

  to {
    transform: translateX(0);
  }
}

// latest-news
.latest-news {
  h2 {
    position: relative;
  }

  .news-list {
    padding: 20px;
    border-radius: 6px;
    min-height: 229px;

    &:hover {
      @include boxShadow($color: rgba(189, 189, 189, 0.6));
    }

    h3,
    p {
      font-size: 17px;
    }

    p {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    a {
      i {
        display: inline-block;
        padding-left: 10px;
      }
    }

  }
}