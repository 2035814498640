@import "../../Style/mixin.scss";

.short-features {
  background: #f9f7f3;

  p {
    font-size: 20px;
  }
}

// about-nextimmmune
.about-nextimmmune {
  position: relative;

  &::before {
    @include beforeContent($bottom: 0, $width: 48%, $height: 61%, $top: unset, $opacity: 1);
    @include bgSetup($bg-image: url('../assets/images/polygonal-shape.svg'), $bg-position: bottom left, $bg-size: contain)
  }
}


//theproblem


// intellectual-property
.intellectual-property {
  position: relative;
  @include bgSetup($bg-image: url('../assets/images/intellectual-property.jpg'), $bg-repeat: no-repeat, $bg-position: top right, $bg-size: cover);

  &::before {
    @include beforeContentLeft($background: linear-gradient(154.23deg, #0A285F 0%, #0F306C 48%, #3869BD 100%), $opacity: 0.8);
  }

  .section-title {
    font-size: 70px;

    @media(max-width: 900px) {
      font-size: 40px;
    }

    @media(max-width: 600px) {
      font-size: 30px;
    }
  }

}

.awards {
  .client-logo {
    border: 1px solid #ededed;
    padding: 10px 20px;
    background: var(--white);
    border-radius: 6px;

    img {
      width: 100%;
    }
  }
}

// innobooster
.innobooster {
  @include lightBlue;

}