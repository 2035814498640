// Flama fonts
@mixin FlamaRegular {
  font-family: 'Flama', sans-serif;
  font-weight: 400;
}

@mixin FlamaRegularItalic {
  font-family: 'Flama', sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin FlamaLight {
  font-family: 'Flama', sans-serif;
  font-weight: 300;
}

@mixin FlamaLightItalic {
  font-family: 'Flama', sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin FlamaBold {
  font-family: 'Flama', sans-serif;
  font-weight: 600;
}

@mixin FlamaBoldItalic {
  font-family: 'Flama', sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin FlamaBlack {
  font-family: 'Flama', sans-serif;
  font-weight: 900;
}

@mixin FlamaBlackItalic {
  font-family: 'Flama', sans-serif;
  font-weight: 900;
  font-style: italic;
}

// Bebas Neue
@mixin BebasNeueThin {
  font-family: 'BebasNeue', sans-serif;
  font-weight: 100;
}

@mixin BebasNeueBook {
  font-family: 'BebasNeue', sans-serif;
  font-weight: 200;
}

@mixin BebasNeueLight {
  font-family: 'BebasNeue', sans-serif;
  font-weight: 300;
}

@mixin BebasNeueRegular {
  font-family: 'BebasNeue', sans-serif;
  font-weight: 400;
}

@mixin BebasNeueBold {
  font-family: 'BebasNeue', sans-serif;
  font-weight: 600;
}



@mixin beforeContent($content: "", $position: absolute, $top: 0, $left: 0, $right: unset, $bottom: unset, $width: 100%, $height: 100%, $opacity: 0.4) {
  content: $content;
  position: $position;
  top: $top;
  bottom: $bottom;
  right: $right;
  left: $left;
  width: $width;
  height: $height;
  opacity: $opacity;
}

@mixin beforeContentLeft($position: absolute, $top: 0, $left: 0, $right: unset, $bottom: unset, $width: 100%, $height: 100%, $background: #000, $opacity: 0.4) {
  content: "";
  position: $position;
  top: $top;
  bottom: $bottom;
  left: $left;
  right: $right;
  width: $width;
  height: $height;
  background: $background;
  opacity: $opacity;
}

@mixin bgSetup ($bg-image: url('../assets/images/pattern/shape-1.svg'), $bg-repeat: no-repeat, $bg-position: center center, $bg-size: cover) {
  background: $bg-image;
  background-repeat: $bg-repeat;
  background-position: $bg-position;
  background-size: $bg-size;
}

@mixin boxShadow ($color: rgba(215, 215, 215, 0.50)) {
  box-shadow: 0 2px 12px $color ;
}

@mixin bgRepeatPosition($repeat: no-repeat, $position: bottom right) {
  background-repeat: $repeat;
  background-position: $position;
}

@mixin bgSize($size: cover) {
  background-size: $size;
}

@mixin lightGraybg {
  background: #eaf3f8;
}

@mixin lightBlue {
  background-color: var(--blue);
}

@mixin card($padding: 30px 30px, $bg-color: #E1ECFF, $br-Radius: 8px) {
  padding: $padding;
  background: $bg-color;
  border-radius: $br-Radius;
}