@import "../../../Style/mixin.scss";

.nextimmune-slider {
  position: relative;
  // margin-bottom: -10px;

  .slider-content {
    display: block;

    .item {
      position: relative;

      // &::before {
      //   @include beforeContent($opacity: 1);
      //   @include bgSetup($bg-image: url('../assets/images/pattern/shape-1.png'), $bg-position: top right, $bg-size: contain);
      //   z-index: 1;
      // }

      &::after {
        @include beforeContentLeft($background: linear-gradient(45deg, var(--dark-blue), transparent), $opacity: 1);
        z-index: 1;
      }
    }

    .slider-caption {
      .ant-col {

        position: relative;
        z-index: 99;
      }

      h1 {
        font-size: 51px;
        line-height: 120%;

        @media(max-width: 600px) {
          font-size: 26px;
          line-height: 30px;
        }
      }

      h2 {
        font-size: 30px;
        line-height: 150%;

        @media(max-width: 600px) {
          font-size: 21px;
          line-height: 150%;
        }
      }

      p {
        font-size: 18px;
      }

    }
  }

  .brands-handle {
    img {
      height: 64px;
    }
  }

  .center-contents {
    height: 100vh;
    min-height: 640px;
    position: relative;
  }

  .nextimmune-banner {
    @include bgSetup($bg-image: url('../assets/images/main-slider/nextimmune.jpg'), $bg-repeat: no-repeat, $bg-position: center center, $bg-size: cover)
  }

  .back-video {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  @media (min-aspect-ratio: 16/9) {
    .back-video {

      width: 100%;
      height: auto;
    }
  }

  @media (max-aspect-ratio: 16/9) {
    .back-video {

      width: auto;
      height: 100%;
    }
  }
}