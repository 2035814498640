@import '../Style/mixin.scss';

.main-hearder {
  display: block;
  padding: 15px 0;
  position: absolute;
  top: 0;
  z-index: 9;
  width: 100%;
  transform: translateZ(0);
  transition: all 0.5s;
  background: var(--white);
  // @include boxShadow($color: rgba(171, 171, 171, 0.50));
  // border: 1px solid rgb(208, 208, 208, 0.15);
  z-index: 9999;

  .anticon-global {
    top: 8px;
    position: absolute;
  }

  .ant-menu-item {
    margin: 10px 10px !important;
  }

  .btn {
    color: var(--white);
  }

  .top-header {
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {

      span {
        margin-left: 10px;
        font-size: 26px;
        color: var(--black);
        // @include luchida;
      }

      img {
        height: 50px;
        padding-right: 10px;

        @media(max-width: 600px) {
          height: 37px;
        }
      }
    }
  }

  .contact-responsive {
    display: none;
  }

  &.scrolled {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    background-color: var(--white);
    @include boxShadow($color: rgba(171, 171, 171, 0.50));
  }

  .contact-button {
    margin-left: 30px;

    @media(max-width:1100px) {
      display: none;
    }
  }
}

// center-header starts
.center-header {
  display: flex;

  @media(max-width:1100px) {
    background: unset;
    border-radius: 0;
    padding: 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    @media(max-width:1100px) {
      display: block;
    }

    li {
      // padding: 0px 8px;

      &:hover {
        color: var(--blue);

        a {
          color: var(--blue);
        }
      }

      a {
        display: block;
        text-align: left;
        font-size: 16px;
        text-transform: uppercase;
        padding: 5px 20px;
        font-weight: 600;
        letter-spacing: 0.5px;
        position: relative;
        color: var(--dark-blue);

        @media(max-width:1100px) {
          font-size: 22px;
          padding: 6px 0 !important;
        }

        &.active {
          position: relative;
        }
      }
    }
  }
}

// center-header ends

.navigations {
  @media(max-width: 1100px) {
    display: none;
    transition: max-height 0.6s cubic-bezier(0, 1, 0, 1);
  }

  &.opened {
    display: block;
    position: fixed;
    right: 0;
    top: 0;
    padding: 53px 30px 30px 30px;
    background: linear-gradient(0deg, var(--dark-blue), var(--blue));
    ;
    height: 100vh;
    min-width: 300px;
    z-index: 999;
    transition: max-height 2s ease-in-out;

    .center-header {
      ul {
        li {
          display: block;
          padding: 0;
          margin-bottom: 10px;

          a {
            color: var(--white);
            padding: 6px 10px;

            &:hover {
              color: var(--white)
            }
          }
        }
      }

    }

    .btn.btn-primary {
      width: 100%;
      background: var(--secondary);
    }

    .contact-responsive {
      display: block;
    }
  }
}

#hamburger {
  display: none;

  @media(max-width: 1100px) {
    display: block;
    position: absolute;
    top: 16px;
    right: 14px;
    width: 40px;
    height: 40px;
    overflow: hidden;
    z-index: 9999;
    background-image: url("../assets/images/open.svg");
    transition: all 0.3s;
    cursor: pointer;

    &.menuopened {
      background-image: url("../assets/images/close.svg");
    }
  }

  @media(max-width: 600px) {
    top: 21px;
  }
}