@import "../../Style/mixin.scss";

.contact-details {
    @include bgSetup($bg-image: url('../assets/images/contact-us.svg'), $bg-position: bottom left, $bg-size: contain);

    .contact-form {
        padding: 30px;
        background: var(--white);
        @include boxShadow;

        .form-field {
            margin-bottom: 6px;

            label {
                font-size: 16px;
                margin-bottom: 0;
            }
        }

    }

    .contact-card {
        padding: 20px;
        position: relative;
        background: var(--blue);

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 20px;
            height: 20px;
            bottom: -20px;
            left: 0;
            background: url('../../assets/images/triangle.svg');
            ;
            background-repeat: no-repeat;
        }

        ul {
            list-style: none;
            padding: 0;

            li {
                padding-top: 10px;
                margin-bottom: 10px;
                border-top: 1px solid #73a3e9;
                font-size: 18px;

                span {
                    font-size: 17px;
                    color: var(--white);
                    display: block;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

.map {
    iframe {
        width: 100%;
        height: 400px;
        margin-bottom: 0;
    }
}