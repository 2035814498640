.footer {
    padding: 40px 0;
    background: var(--dark-blue);

    h3 {
        font-size: 22px;
        color: var(--white);
    }

    p {
        font-size: 15px;
    }

    .info-links {
        width: 100%;

        ul {
            list-style: none;
            padding: 0;

            li {
                margin-bottom: 12px;

                a {
                    color: var(--gray-color);
                    font-size: 16px;
                    line-height: 1.19;
                    display: inline-block;

                    &:hover {
                        color: var(--white);
                    }
                }

                &:last-child {
                    a {
                        margin-bottom: 0;
                    }
                }

            }

        }
    }

    .social-media {
        ul {
            margin: 0;
            padding: 0;

            li {
                display: inline-block;

                a {
                    font-size: 22px;
                    color: var(--light-gray);
                    margin-right: 16px;

                    &:hover {
                        color: var(--blue);
                    }
                }

                &:last-child {
                    a {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .bottom-footer {
        border-top: 1px solid #1c3381;
    }
}

// back to top starts
.ant-back-top {
    right: 26px !important;
    bottom: 20px !important;
}

.back-to-top {
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 4px;
    background-color: var(--blue);
    color: #fff;
    text-align: center;
    font-size: 15px;
}